<template>
  <q-page v-if="data">
    <iframe width="100%" height="100%" :src="`data:application/pdf;base64,${data}`"></iframe>
  </q-page>
</template>

<script>
export default {
  name: 'LoanAgreementPage',
  data () {
    return {
      data: null
    }
  },
  async mounted () {
    await this.$dispatch('applications/getApplications')

    this.data = await this.$dispatch({
      action: 'applications/getEdoc',
      params: this.application().edoc().get('id'),
      message: 'Retrieving Contract'
    }, true)
  },
  methods: {
    application () {
      return this.$store.getters['applications/getApplicationByRef'](this.$route.params.loanRef)
    }
  }
}
</script>

<style scoped>
  iframe {
    height:1000px
  }
</style>
